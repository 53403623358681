import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { MenuController, Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { IhwService } from './providers/ihw.service';
import { MenuService } from './providers/menu.service';

@Component({
  selector:      'app-root',
  templateUrl:   './app.component.html',
  styleUrls:     ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  // TODO how to update icons on properties in masterModel.
  appPages: any = [];
  loggedIn = false;
  dark = false;
  taken: any;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    public ihwService: IhwService,
    public menuService: MenuService,
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    // this.checkLoginStatus();
    // this.listenForLoginEvents();
    //
    // this.swUpdate.available.subscribe(async res => {
    //   const toast = await this.toastCtrl.create({
    //     message:  'Update available!',
    //     position: 'bottom',
    //     buttons:  [
    //       {
    //         role: 'cancel',
    //         text: 'Reload',
    //       },
    //     ],
    //   });
    //
    //   await toast.present();
    //
    //   toast
    //     .onDidDismiss()
    //     .then(() => this.swUpdate.activateUpdate())
    //     .then(() => window.location.reload());
    // });

    // Handle incoming taken fom url params.
    const params = new URLSearchParams(window.location.search);
    if (params) {
      const takenJson = params.get('taken');
      if (takenJson) {
        const incomingTaken = JSON.parse(takenJson);
        this.ihwService.model.taken = this.taken = incomingTaken.map(i => {
          i.isChecked = true;
          return i;
        });
      }
    }

    this.appPages = this.menuService.appPages;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/app/tabs/schedule');
    });
  }

}
