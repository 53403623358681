import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  appPages = [
    {
      disabled: false,
      visited:  true,
      title:    'Welkom',
      url:      '/',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 1: Takenpakket',
      url:      '/step-1',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 2: Kerntaken',
      url:      '/step-2',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 3: Taakniveau',
      url:      '/step-3',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 4: Taakenergie',
      url:      '/step-4',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 5: Taakwens',
      url:      '/step-5',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 6: Taaktijd',
      url:      '/step-6',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 7: Taakherontwerp',
      url:      '/step-7',
    },
    {
      disabled: false,
      visited:  false,
      title:    'Stap 8: Taakoverzicht',
      url:      '/step-8',
    },
  ];

  constructor() { }

}
