import { Injectable } from '@angular/core';
import { TaakDto } from '../pages/mooi-werk/dto/taak.dto';

@Injectable({
  providedIn: 'root',
})
export class IhwService {
  model: { taken: TaakDto[] } = {
    taken: [
      {
        naam:         'Test taak 1',
        omschrijving: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        isChecked:    true,
        size:         Math.floor(Math.random() * 100),
        suitable:     'unknown',
        overview:     {
          label: null,
          color: null,
          index: null,
        },
        opened:       false,
        type:         {
          index: 0,
          label: 'Kerntaak',
          color: 'tertiary',
        },
        complexity:   'complex',
        energy:       {
          label: 'Energiegever',
          color: 'tertiary',
          index: 2,
        },
        wish:         {
          label: 'Minder doen',
          color: 'medium',
          index: 3,
        },
      },
      {
        naam:         'Test taak 2',
        omschrijving: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        isChecked:    true,
        size:         Math.floor(Math.random() * 100),
        suitable:     'unknown',
        overview:     {
          label: null,
          color: null,
          index: null,
        },
        opened:       false,
        type:         {
          index: 1,
          label: 'Ondersteunende taak',
          color: 'medium',
        },
        complexity:   'simple',
        energy:       {
          label: 'Energienemer',
          color: 'medium',
          index: 0,
        },
        wish:         {
          label: 'Anders doen',
          color: 'primary',
          index: 1,
        },
      },
      {
        naam:         'Test taak 3',
        omschrijving: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        isChecked:    true,
        size:         Math.floor(Math.random() * 100),
        type:         {
          index: 2,
          label: 'Extra taak',
          color: 'primary',
        },
        opened:       true,
        complexity:   'unknown',
        energy:       {
          label: 'Energieneutraal',
          color: 'white',
          index: 1,
        },
        wish:         {
          label: 'Beter doen',
          color: 'light',
          index: 2,
        },
        suitable:     'unknown',
        overview:     {
          label: null,
          color: null,
          index: null,
        },
      },
      {
        naam:       'Test taak 4',
        isChecked:  true,
        size:       Math.floor(Math.random() * 100),
        type:       {
          index: 2,
          label: 'Extra taak',
          color: 'primary',
        },
        opened:     true,
        complexity: 'unknown',
        energy:     {
          label: 'Energieneutraal',
          color: 'white',
          index: 1,
        },
        wish:       {
          label: 'Meer doen',
          color: 'tertiary',
          index: 0,
        },
        suitable:   'unknown',
        overview:   {
          label: null,
          color: null,
          index: null,
        },
      },
      {
        naam:       'Test taak 5',
        isChecked:  true,
        size:       Math.floor(Math.random() * 100),
        type:       {
          index: 2,
          label: 'Extra taak',
          color: 'primary',
        },
        opened:     true,
        complexity: 'unknown',
        energy:     {
          label: 'Energieneutraal',
          color: 'white',
          index: 1,
        },
        wish:       {
          label: 'Meer doen',
          color: 'tertiary',
          index: 0,
        },
        suitable:   'unknown',
        overview:   {
          label: null,
          color: null,
          index: null,
        },
      },
      // {
      //   naam:       'Test taak 6',
      //   isChecked:  true,
      //   size:       Math.floor(Math.random() * 100),
      //   type:       {
      //     index: 2,
      //     label: 'Extra taak',
      //     color: 'primary',
      //   },
      //   opened:     true,
      //   complexity: 'unknown',
      //   energy:     {
      //     label: 'Energieneutraal',
      //     color: 'white',
      //     index: 1,
      //   },
      //   wish:       {
      //     label: 'Meer doen',
      //     color: 'tertiary',
      //     index: 0,
      //   },
      //   suitable:   'unknown',
      //   overview:   {
      //     label: null,
      //     color: null,
      //     index: null,
      //   },
      // },
      // {
      //   naam:       'Test taak 7',
      //   isChecked:  true,
      //   size:       Math.floor(Math.random() * 100),
      //   type:       {
      //     index: 2,
      //     label: 'Extra taak',
      //     color: 'primary',
      //   },
      //   opened:     true,
      //   complexity: 'unknown',
      //   energy:     {
      //     label: 'Energieneutraal',
      //     color: 'white',
      //     index: 1,
      //   },
      //   wish:       {
      //     label: 'Meer doen',
      //     color: 'tertiary',
      //     index: 0,
      //   },
      //   suitable:   'unknown',
      //   overview:   {
      //     label: null,
      //     color: null,
      //     index: null,
      //   },
      // },
      // {
      //   naam:       'Test taak 8',
      //   isChecked:  true,
      //   size:       Math.floor(Math.random() * 100),
      //   type:       {
      //     index: 2,
      //     label: 'Extra taak',
      //     color: 'primary',
      //   },
      //   opened:     true,
      //   complexity: 'unknown',
      //   energy:     {
      //     label: 'Energieneutraal',
      //     color: 'white',
      //     index: 1,
      //   },
      //   wish:       {
      //     label: 'Meer doen',
      //     color: 'tertiary',
      //     index: 0,
      //   },
      //   suitable:   'unknown',
      //   overview:   {
      //     label: null,
      //     color: null,
      //     index: null,
      //   },
      // },
      // {
      //   naam:       'Test taak 9',
      //   isChecked:  true,
      //   size:       Math.floor(Math.random() * 100),
      //   type:       {
      //     index: 2,
      //     label: 'Extra taak',
      //     color: 'primary',
      //   },
      //   opened:     true,
      //   complexity: 'unknown',
      //   energy:     {
      //     label: 'Energieneutraal',
      //     color: 'white',
      //     index: 1,
      //   },
      //   wish:       {
      //     label: 'Meer doen',
      //     color: 'tertiary',
      //     index: 0,
      //   },
      //   suitable:   'unknown',
      //   overview:   {
      //     label: null,
      //     color: null,
      //     index: null,
      //   },
      // },
      // {
      //   naam:       'Test taak 10',
      //   isChecked:  true,
      //   size:       Math.floor(Math.random() * 100),
      //   type:       {
      //     index: 2,
      //     label: 'Extra taak',
      //     color: 'primary',
      //   },
      //   opened:     true,
      //   complexity: 'unknown',
      //   energy:     {
      //     label: 'Energieneutraal',
      //     color: 'white',
      //     index: 1,
      //   },
      //   wish:       {
      //     label: 'Meer doen',
      //     color: 'tertiary',
      //     index: 0,
      //   },
      //   suitable:   'unknown',
      //   overview:   {
      //     label: null,
      //     color: null,
      //     index: null,
      //   },
      // },
    ],
  };

  // TODO: why does assignment here (instead of in the constructor not show
  //  these properties (e.g. in mooi-werk-8.ts)?
  COMPLEXITY: any;
  SUITABLE: any;

  constructor() {
    this.COMPLEXITY = {
      complex: {
        labelInput:    'Complexe taken',
        labelOverview: 'Complex',
      },
      unknown: {
        labelInput:    'Nog te verdelen taken',
        labelOverview: 'Niet bepaald',
      },
      simple:  {
        labelInput:    'Routine/eenvoudige taken',
        labelOverview: 'Eenvoudige taak',
      },
    };

    this.SUITABLE = {
      yes:     {
        labelInput:    'Taak mogelijk geschikt voor herontwerp',
        labelOverview: 'Geschikt voor herontwerp',
      },
      unknown: {
        labelInput:    'Nog te verdelen taken',
        labelOverview: 'Nog te verdelen taken',
      },
      no:      {
        labelInput:    'Taak ongeschikt voor herontwerp',
        labelOverview: 'Ongeschikt voor herontwerp',
      },
    };
  }

  getTaken() {
    return this.model.taken;
  }

  onExport(): void {
    const filename = 'Beter werk tool.ihw';
    const content = this.rot13(
      JSON.stringify({
          meta:  {
            type:       'survey',
            appVersion: 'x.x.x',
            createdAt:  new Date().toISOString(),
          },
          model: this.model,
        },
      ),
    );

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  rot13(str: string): string {
    const input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
    const index = x => input.indexOf(x);
    const translate = x => index(x) > -1 ? output[index(x)] : x;
    return str.split('').map(translate).join('');
  }

}
